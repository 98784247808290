// Polyfill complet pour l'objet process dans le navigateur
if (typeof window !== 'undefined') {
  // Si process n'existe pas du tout, le créer
  if (typeof process === 'undefined') {
    window.process = {};
  }
  
  // Assurer que window.process est un objet
  if (typeof window.process !== 'object') {
    window.process = {};
  }

  // Définir les propriétés essentielles si elles n'existent pas déjà
  if (!window.process.env) {
    window.process.env = {};
  }
  
  // Définir NODE_ENV s'il n'est pas déjà défini
  if (!window.process.env.NODE_ENV) {
    window.process.env.NODE_ENV = 'development';
  }
  
  // Définir platform s'il n'est pas déjà défini
  if (!window.process.platform) {
    window.process.platform = 'browser';
  }
  
  // Autres propriétés communes utilisées par les bibliothèques
  if (!window.process.argv) window.process.argv = [];
  if (!window.process.version) window.process.version = '';
  if (!window.process.versions) window.process.versions = {};
  if (!window.process.nextTick) {
    window.process.nextTick = function(callback) {
      setTimeout(callback, 0);
    };
  }
  
  // Ajouter d'autres propriétés/méthodes couramment utilisées
  if (!window.process.cwd) {
    window.process.cwd = function() { return '/'; };
  }
  if (!window.process.browser) window.process.browser = true;
  
  // Hack spécifique pour le code que vous avez montré
  // Si l'erreur provient d'une condition comme "win32" === process.platform && ...
  // On s'assure que cette condition sera false pour ne pas causer d'erreur
  if (window.process.platform === 'browser') {
    // Une condition "win32" === process.platform sera toujours fausse
    // Cela devrait éviter l'exécution du code problématique
  }
}

export default {}; 