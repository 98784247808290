import React, { useContext } from 'react';
import ReservationContext from './webService/Reservations';
import './TableSelector.css'; // Assurez-vous que le chemin est correct pour vos styles CSS

const TableSelector = ({ table }) => {
  const { handleSelectTable, selectedTable } = useContext(ReservationContext);

  const handleSelect = () => {
    console.log("Table sélectionnée:", table);
    handleSelectTable(table.id);
  };

  // Détermine si la table est sélectionnée pour appliquer la classe CSS "selected"
  const isSelected = selectedTable === table.id;

  return (
    <div className={`table-card ${isSelected ? 'selected' : ''}`}
        onClick={handleSelect}>
        <div className={`table-name ${isSelected ? 'selected' : ''}`}>{table.name}</div>
    </div>
  );
};

export default TableSelector;

