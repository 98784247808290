import React, { useContext, useEffect, useState } from 'react';
import './Dropdown.css';
import ReservationContext from './webService/Reservations';

const SelectorWithDropdown = ({ options, containerName, title }) => {
  const { handleSelectSlot, handleSelectCustomers, resetStates, setHoraireSelection, setQuantitySelection  } = useContext(ReservationContext);
  const [isOpen, setIsOpen] = useState(false);
  const [customersSelection, setCustomersSelection] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const toggleDropdown = () => setIsOpen(!isOpen);

  // Log au montage du composant
  useEffect(() => {
    console.log(`🧩 SelectorWithDropdown monté - containerName: ${containerName}, options:`, options);
  }, []);

  const getButtonText = () => {
    if (containerName === 'customers') {
      return customersSelection || 'Choisir';
    } else if (containerName === 'slots') {
      return selectedSlot ? selectedSlot.time : 'Choisir';
    }
  };

  const handleSelection = (selection) => {
    console.log(`🔄 handleSelection appelé pour ${containerName} avec la sélection:`, selection);

    if (containerName === "customers") {
      if (selection !== null) {
        console.log("✅ Sélection de clients valide:", selection);
        
        // D'abord mettre à jour la sélection locale
        setCustomersSelection(selection);
        
        // Appliquer directement le changement sans setTimeout
        console.log("🔥 Appel direct de setQuantitySelection(true)");
        setQuantitySelection(true);
      } else {
        console.log("❌ Sélection de clients invalide: null");
      }
    } else {
        console.log("handleSelection slots", selection, options);
        setHoraireSelection(true);
        setSelectedSlot(selection);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    console.log(`👥 useEffect customersSelection: ${customersSelection}`);
    handleSelectCustomers(customersSelection);
  }, [customersSelection]);

  useEffect(() => {
    console.log(`🕒 useEffect selectedSlot:`, selectedSlot);
    // Only call handleSelectSlot if selectedSlot is not null
    // This prevents unnecessary calls on initial render
    if (selectedSlot !== null) {
      handleSelectSlot(selectedSlot);
    }
  }, [selectedSlot]);

  useEffect(() => {
    if (resetStates === true) {
      console.log("🔄 Réinitialisation des états car resetStates est true");
      
      // For slots container, reset the slot selection but preserve QuantitySelected
      if (containerName === "slots") {
        setSelectedSlot(null);
      } 
      // For customers container, reset everything
      else if (containerName === "customers") {
        setCustomersSelection(null);
        setQuantitySelection(false);
      }
    }
  }, [resetStates, containerName]);

  return (
      <div className="calendar-header-section">
          <h2 className="dropdown-title">{title}</h2>
          <div className='dropdown-block'>
            <button className="dropdown-button" onClick={toggleDropdown}>
              {getButtonText()}
            </button>
            <div className={`dropdown-list-container ${isOpen ? 'open' : ''}`}>
              <ul className="dropdown-list">
                {containerName === 'customers' ? (
                  options.map((option, index) => (
                  <li key={index} className="dropdown-list-item" onClick={() => {
                    console.log(`👆 Clic sur l'option client: ${option}`);
                    handleSelection(option);
                  }}>
                    {option}
                  </li>
                ))
                ) : (
                  options.map((option, index) => (
                    <li
                      key={index}
                      className={`dropdown-list-item ${option.tables.length === 0 ? "non-clickable" : ""}`}
                      onClick={() => {
                        if (option.tables.length !== 0) {
                          console.log(`👆 Clic sur l'option horaire: ${option.time}`);
                          handleSelection(option);
                        }
                      }}
                    >
                      {option.time}
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>  
      </div>
    );
};
export default SelectorWithDropdown;

