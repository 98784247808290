// Script pour récupérer, cloner et modifier un document de TablesReservations
import { firestore } from '../firebaseconfig.js';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

/**
 * Récupère les données d'un document spécifique de la collection TablesReservations
 * @param {string} documentId - L'ID du document à récupérer
 * @returns {Object|null} Les données du document ou null si non trouvé
 */
async function fetchTableDocument(documentId) {
  try {
    console.log(`Récupération du document ${documentId}...`);
    
    const docRef = doc(firestore, 'TablesReservations', documentId);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const data = docSnap.data();
      console.log(`Document trouvé: ${documentId} (Restaurant: ${data.idRestau})`);
      return data;
    } else {
      console.log('Document non trouvé!');
      return null;
    }
  } catch (error) {
    console.error('Erreur lors de la récupération du document:', error);
    return null;
  }
}

/**
 * Vérifie si un objet est un timestamp (en vérifiant sa structure)
 * @param {Object} obj - L'objet à vérifier
 * @returns {boolean} Vrai si l'objet ressemble à un timestamp
 */
function isTimestampLike(obj) {
  return obj && 
         typeof obj === 'object' && 
         obj.seconds !== undefined && 
         obj.nanoseconds !== undefined;
}

/**
 * Convertit un objet timestamp-like en Date JavaScript
 * @param {Object} timestampObj - L'objet de type timestamp
 * @returns {Date} Un objet Date JavaScript
 */
function timestampToDate(timestampObj) {
  if (!isTimestampLike(timestampObj)) {
    return new Date(); // Date par défaut
  }
  
  // Conversion depuis les secondes et nanosecondes en millisecondes
  return new Date(timestampObj.seconds * 1000 + timestampObj.nanoseconds / 1000000);
}

/**
 * Transforme les données selon les spécifications
 * @param {Object} originalData - Les données originales du document
 * @returns {Object} Les données transformées avec le nouvel ID de document
 */
function transformTableData(originalData) {
  if (!originalData) return null;
  
  // Copie profonde des données pour éviter de modifier l'original
  const transformedData = JSON.parse(JSON.stringify(originalData));
  
  // Générer un nouvel ID de document
  const newDocId = uuidv4();
  console.log(`Nouvel ID de document généré: ${newDocId}`);
  
  // Changer l'ID du restaurant
  transformedData.idRestau = "9NOAKw6WpmOuRoAloglp002VSur1";
  
  // Parcourir les jours de la semaine
  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  const serviceIds = {};
  
  days.forEach(day => {
    if (transformedData[day] && transformedData[day].services) {
      serviceIds[day] = [];
      
      // Parcourir les services de ce jour
      transformedData[day].services.forEach((service, index) => {
        // Générer un nouvel ID pour le service
        service.id = uuidv4();
        serviceIds[day].push({
          index: index,
          newId: service.id
        });
        
        try {
          // Vérifier et traiter la date de début
          if (service.dateStartService) {
            // Créer une nouvelle date de fin à 22:00:10 UTC+2
            let sameDay;
            
            if (isTimestampLike(service.dateStartService)) {
              // Convertir le timestamp en Date
              const startDate = timestampToDate(service.dateStartService);
              sameDay = new Date(startDate);
            } else if (typeof service.dateStartService === 'string') {
              // Si c'est déjà une chaîne, essayer de l'analyser
              sameDay = new Date(service.dateStartService);
            } else {
              // Fallback au jour actuel
              sameDay = new Date();
            }
            
            // Définir l'heure à 22:00:10
            sameDay.setHours(22, 0, 10);
            
            // Créer un nouveau timestamp Firestore
            service.dateEndService = {
              seconds: Math.floor(sameDay.getTime() / 1000),
              nanoseconds: 0
            };
          }
        } catch (error) {
          console.error(`Erreur lors du traitement des dates:`, error);
        }
      });
    }
  });
  
  return { transformedData, newDocId, serviceIds };
}

/**
 * Enregistre les données transformées dans un nouveau document Firestore
 * @param {Object} transformedData - Les données transformées
 * @param {string} newDocId - L'ID du nouveau document
 * @returns {boolean} Succès ou échec de l'opération
 */
async function uploadTransformedData(transformedData, newDocId) {
  try {
    console.log(`Création du document avec ID: ${newDocId}...`);
    
    const newDocRef = doc(firestore, 'TablesReservations', newDocId);
    await setDoc(newDocRef, transformedData);
    
    console.log('Nouveau document créé avec succès!');
    return true;
  } catch (error) {
    console.error('Erreur lors de la création du nouveau document:', error);
    return false;
  }
}

/**
 * Processus complet de clonage et modification d'une table
 * @param {string} sourceDocumentId - L'ID du document source à cloner
 * @returns {Object} Résultat de l'opération
 */
async function cloneAndModifyTable(sourceDocumentId = '0061E01D-8DC1-41D6-BEFF-0FA65EE0DC0E') {
  try {
    console.log(`Clonage du document ${sourceDocumentId}...`);
    
    // 1. Récupérer les données d'origine
    const originalData = await fetchTableDocument(sourceDocumentId);
    if (!originalData) {
      return { success: false, message: 'Document source non trouvé' };
    }
    
    // 2. Transformer les données
    const { transformedData, newDocId } = transformTableData(originalData);
    
    // 3. Uploader les données transformées
    const uploadSuccess = await uploadTransformedData(transformedData, newDocId);
    
    if (uploadSuccess) {
      console.log(`Table clonée avec succès. Nouvel ID: ${newDocId}`);
      
      return { 
        success: true, 
        message: 'Table clonée et modifiée avec succès', 
        newDocId,
        restaurantId: transformedData.idRestau
      };
    } else {
      return { 
        success: false, 
        message: 'Échec lors de l\'upload des données transformées' 
      };
    }
  } catch (error) {
    console.error('Erreur lors du clonage:', error);
    return { 
      success: false, 
      message: 'Erreur lors du processus de clonage', 
      error: error.message 
    };
  }
}

// Pour utilisation dans d'autres modules
export { fetchTableDocument, cloneAndModifyTable };
export default fetchTableDocument; 