import React, { useContext, useState, useEffect } from 'react';
import './RestauList.css'; // N'oublie pas de créer le fichier CSS correspondant
import './RestauCard.css';
import placeholderIcon from './logo.svg'; // Icône de placeholder, remplace-la par l'importation de tes icônes
import ReservationContext from './webService/Reservations';
import logo from './logo.svg';
import { ActionType } from './ActionType'; // Importation de l'enum

const RestauList = ({ actions, externalSelectedId = null }) => {
  const [selectedId, setSelectedId] = useState(null); // Add state to track the selected action
  console.log("🏪 RestauList - État selectedId:", selectedId);
  console.log("🏪 RestauList - Prop externalSelectedId:", externalSelectedId);

  // Si externalSelectedId change, on met à jour selectedId
  useEffect(() => {
    if (externalSelectedId !== null) {
      console.log("🏪 RestauList - Mise à jour de selectedId par externalSelectedId:", externalSelectedId);
      setSelectedId(externalSelectedId);
    }
  }, [externalSelectedId]);

  const handleClick = async (id, onClick) => {
    console.log(`🏪 RestauList - handleClick appelé pour l'ID ${id}`);
    // On met à jour seulement si la fonction onClick ne retourne pas true (restaurant fermé)
    console.log(`🏪 RestauList - Appel de la fonction onClick pour l'ID ${id}`);
    const result = await onClick();
    console.log(`🏪 RestauList - Résultat de onClick pour l'ID ${id}:`, result);
    
    // Si onClick retourne undefined (comportement normal) ou false (restaurant non fermé)
    // On met à jour l'état selectedId
    if (result !== true) {
      console.log(`🏪 RestauList - Mise à jour de selectedId avec ${id}`);
      setSelectedId(id);
    } else {
      console.log(`🏪 RestauList - Pas de mise à jour de selectedId car restaurant fermé`);
    }
  };

  return(
    <div className="action-list">
    {actions.map((action, index) => (
      <div key={action.id} className={`action-item ${index === 0 && actions.length % 2 !== 0 ? 'full-width' : ''}`}>
        <RestauCard 
          icon={action.icon} 
          title={action.title} 
          isSelected={selectedId === action.id} 
          onClick={() => handleClick(action.id, action.onClick)} 
        />
      </div>
    ))}
  </div>
  );
};

const RestauCard = ({ icon = placeholderIcon, title, isSelected, onClick }) => {
  console.log(`🏪 RestauCard - Rendu pour ${title}, isSelected:`, isSelected);
  
  return(
      <div className={`action-card ${isSelected ? 'selected' : ''}`} onClick={onClick} role="button" tabIndex={0}>
          <p className="action-card-title">{title}</p>
      </div>
  );
};

export default RestauList;
