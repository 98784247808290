// Script pour synchroniser les IDs des tables d'un restaurant spécifique
import { firestore } from '../firebaseconfig.js';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

// Jours de la semaine à traiter
const DAYS_OF_WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

// Configuration des tables à mettre à jour
const TABLE_CONFIG = [
  { title: "Table pour 2", sits: 2, count: 10 },
  { title: "Table pour 4", sits: 4, count: 4 },
  { title: "Table pour 6", sits: 6, count: 2 },
  { title: "Table pour 8", sits: 8, count: 1 }
];

/**
 * Récupère toutes les tables d'un restaurant spécifique et synchronise:
 * - l'ID interne avec l'ID du document
 * - les IDs des services pour chaque jour
 * - les titres et nombres de places selon la configuration
 * 
 * @param {string} restaurantId - L'ID du restaurant à traiter
 * @returns {Promise<Object>} Résultat de la synchronisation
 */
async function syncTableIds(restaurantId = '9NOAKw6WpmOuRoAloglp002VSur1') {
  try {
    console.log(`🔄 Synchronisation des tables pour restaurant: ${restaurantId}`);
    
    // Créer une requête pour récupérer toutes les tables du restaurant
    const tablesCollection = collection(firestore, 'TablesReservations');
    const q = query(tablesCollection, where('idRestau', '==', restaurantId));
    
    // Exécuter la requête
    const querySnapshot = await getDocs(q);
    
    // Vérifier si des tables ont été trouvées
    if (querySnapshot.empty) {
      console.log('❌ Aucune table trouvée pour ce restaurant.');
      return { success: false, message: 'Aucune table trouvée', tables: [] };
    }
    
    console.log(`✅ ${querySnapshot.size} tables trouvées.`);
    
    if (querySnapshot.size !== 17) {
      console.log(`⚠️ Attention: ${querySnapshot.size} tables trouvées, mais 17 attendues.`);
    }
    
    const updatedTables = [];
    const updatePromises = [];
    
    // Préparer la distribution des types de tables
    let tableTypeIndex = 0;
    let currentTypeCount = 0;
    let tablesProcessed = 0;
    
    // Traiter chaque table
    querySnapshot.forEach((docSnapshot) => {
      tablesProcessed++;
      const tableId = docSnapshot.id;
      const tableData = docSnapshot.data();
      const updatedData = { ...tableData };
      const changes = [];
      
      // 1. Synchroniser l'ID interne avec l'ID du document
      if (tableData.id !== tableId) {
        updatedData.id = tableId;
        changes.push(`ID interne mis à jour: ${tableData.id || 'Non défini'} → ${tableId}`);
      }
      
      // 2. Mettre à jour le titre et le nombre de places selon la configuration
      if (tablesProcessed <= 17) {
        // Déterminer le type de table à appliquer
        if (currentTypeCount >= TABLE_CONFIG[tableTypeIndex].count) {
          tableTypeIndex++;
          currentTypeCount = 0;
          // Vérification pour éviter les index hors limites
          if (tableTypeIndex >= TABLE_CONFIG.length) {
            console.log(`⚠️ Attention: Toutes les configurations de tables ont été utilisées. Utilisation de la dernière configuration.`);
            tableTypeIndex = TABLE_CONFIG.length - 1;
          }
        }
        
        const tableConfig = TABLE_CONFIG[tableTypeIndex];
        currentTypeCount++;
        
        // Mettre à jour le titre et le nombre de places
        if (tableData.name !== tableConfig.title) {
          updatedData.name = tableConfig.title;
          changes.push(`Titre mis à jour: ${tableData.name || 'Non défini'} → ${tableConfig.title}`);
        }
        
        if (tableData.sits !== tableConfig.sits) {
          updatedData.sits = tableConfig.sits;
          changes.push(`Places mises à jour: ${tableData.sits || 'Non défini'} → ${tableConfig.sits}`);
        }
      }
      
      // 3. Générer des UUIDs pour les services de chaque jour
      DAYS_OF_WEEK.forEach(day => {
        if (tableData[day] && tableData[day].services && Array.isArray(tableData[day].services)) {
          let servicesUpdated = false;
          updatedData[day] = { ...tableData[day] };
          updatedData[day].services = tableData[day].services.map(service => {
            const newService = { ...service };
            newService.id = uuidv4();
            servicesUpdated = true;
            return newService;
          });
          
          if (servicesUpdated) {
            changes.push(`Services de ${day} mis à jour avec IDs uniques`);
          }
        }
      });
      
      // Si des changements ont été détectés, créer une promesse de mise à jour
      if (changes.length > 0) {
        console.log(`📝 Table ${tableId} (${tablesProcessed}/${querySnapshot.size}):`);
        changes.forEach(change => console.log(`  - ${change}`));
        
        // Créer une référence au document
        const tableRef = doc(firestore, 'TablesReservations', tableId);
        
        // Ajouter la promesse de mise à jour à la liste
        updatePromises.push(
          updateDoc(tableRef, updatedData)
            .then(() => {
              updatedTables.push({ id: tableId, changes });
              return { id: tableId, success: true, changes };
            })
            .catch((error) => {
              console.error(`❌ Erreur lors de la mise à jour de ${tableId}: ${error.message}`);
              return { id: tableId, success: false, error: error.message };
            })
        );
      } else {
        console.log(`ℹ️ Table ${tableId} (${tablesProcessed}/${querySnapshot.size}): Aucun changement nécessaire`);
      }
    });
    
    // Attendre que toutes les mises à jour soient terminées
    if (updatePromises.length > 0) {
      console.log(`🔄 Traitement de ${updatePromises.length} mises à jour...`);
      const results = await Promise.all(updatePromises);
      
      // Générer le rapport final
      const successCount = results.filter(r => r.success).length;
      console.log('\n📊 RAPPORT DE SYNCHRONISATION:');
      console.log(`✅ ${successCount} tables mises à jour avec succès`);
      console.log(`❌ ${results.length - successCount} tables en échec`);
      
      // Vérification des sits pour s'assurer que maxCustomers sera correctement calculé
      let maxSits = 0;
      updatedTables.forEach(table => {
        if (table.sits && table.sits > maxSits) {
          maxSits = table.sits;
        }
      });
      
      console.log(`📏 Valeur maximale de 'sits' après mise à jour: ${maxSits}`);
      
      const tableSummary = TABLE_CONFIG.map(config => 
        `${config.count} × "${config.title}" (${config.sits} places)`
      ).join(', ');
      
      console.log(`📋 Distribution des tables: ${tableSummary}`);
      console.log('✨ Synchronisation terminée!');
    } else {
      console.log('ℹ️ Aucune mise à jour nécessaire.');
    }
    
    return {
      success: true,
      message: `${updatedTables.length} tables synchronisées avec succès`,
      tables: updatedTables
    };
    
  } catch (error) {
    console.error('❌ Erreur:', error);
    return {
      success: false,
      message: 'Erreur lors de la synchronisation des tables',
      error: error.message
    };
  }
}

// Pour utilisation dans d'autres modules
export default syncTableIds;

// Permettre l'exécution directe du script
if (typeof require !== 'undefined' && require.main === module) {
  const args = process.argv.slice(2);
  const restaurantId = args[0] || '9NOAKw6WpmOuRoAloglp002VSur1';
  
  syncTableIds(restaurantId)
    .then(result => {
      console.log('📋 Résultat final:', result.message);
      if (!result.success) {
        process.exit(1);
      }
    })
    .catch(error => {
      console.error('❌ Erreur non gérée:', error);
      process.exit(1);
    });
} 