import React, { createContext, useContext, useState, useEffect } from "react";
import { firestore, usersCollection } from "../firebaseconfig";
import { Timestamp, getDoc, doc, setDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { getResaDay, isBetween } from "../utils/utils";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import getAvailableTimeSlots, {
  timeStringToMinutes,
  getDayOfWeek,
} from "../horairesSelectable";

// Créez un contexte pour les réservations
export const ReservationContext = createContext(null);
// Utilisez un Provider pour envelopper la partie de votre application qui a besoin d'accès aux réservations
export const ReservationProvider = ({ children }) => {
  const [resetStates, setResetStates] = useState(false);
  const [tables, setTables] = useState([]);
  const [maxCustomers, setMaxCustomers] = useState(2);
  const [calendardate, setCalendardate] = useState(new Date()); // Ici, vous pouvez mettre la date actuelle ou toute autre date par défaut
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedHoraire, setSelectedHoraire] = useState("");
  const [selectedTableIds, setSelectedTableIds] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null); // Pour stocker l'ID de la table sélectionnée
  const [selectedService, setSelectedService] = useState(null); // Pour stocker le service de la table sélectionnée
  const [table, setTable] = useState(null); // Pour stocker la table sélectionnée
  const [phoneNumber, setPhoneNumber] = useState("");
  const [customersNumber, setCustomersNumber] = useState(2);
  const [notes, setNotes] = useState("");
  const [restauSelected, setRestauSelected] = useState(null);
  const [allReservations, setallReservations] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [QuantitySelected, setQuantitySelectionOriginal] = useState(false);
  const [HoraireSelected, setHoraireSelection] = useState(false);
  const [isRestaurantTemporarilyClosed, setIsRestaurantTemporarilyClosed] = useState(false);
  const [closedReason, setClosedReason] = useState("Ce restaurant est temporairement fermé. Veuillez choisir un autre restaurant.");

  // Wrapper setQuantitySelection pour tracer les appels
  const setQuantitySelection = (value) => {
    console.log("🔴 setQuantitySelection appelé avec:", value);
    // Ensure we're setting a boolean value
    const boolValue = Boolean(value);
    setQuantitySelectionOriginal(boolValue);
  };
  
  // Observer les changements de QuantitySelected
  useEffect(() => {
    console.log("🔴 QuantitySelected est maintenant:", QuantitySelected);
  }, [QuantitySelected]);

  const handleCustomerNameChanges = (name) => {
    setCustomerName(name);
  };

  const getCustomersNumber = () => {
    return customersNumber;
  };

  const resetWhenRestauChange = () => {
    setResetStates(true);
  };

  useEffect(() => {
    console.log("resettedState", resetStates);
    if (resetStates === true) {
      setallReservations([]);
      setCustomersNumber(null);
      setMaxCustomers(1);
      setTables([]);
      setTable(null);
      setTimeSlots([]);
      setSelectedHoraire("");
      setSelectedTableIds([]);
      setSelectedTable(null);
      setSelectedService(null);
      setResetStates(false);
    }
  }, [resetStates]);

  useEffect(() => {
    console.log("customerName setted:", customerName);
  }, [customerName]);

  const canClickReservation = (iscliquable) => {
    console.log("🔍 DEBUG canClickReservation - Vérification des conditions:");
    console.log("  - phoneNumber:", phoneNumber ? `"${phoneNumber}"` : "non défini", `(valide: ${Boolean(phoneNumber.trim())})`);
    console.log("  - selectedService:", selectedService ? `ID: ${selectedService}` : "non défini", `(valide: ${Boolean(selectedService)})`);
    console.log("  - selectedTable:", selectedTable || "non défini", `(valide: ${Boolean(selectedTable)})`);
    console.log("  - table:", table ? `Nom: ${table.name}` : "non défini", `(valide: ${Boolean(table)})`);
    console.log("  - customersNumber:", customersNumber, `(valide: ${customersNumber !== undefined})`);
    console.log("  - customerName:", customerName ? `"${customerName}"` : "non défini", `(valide: ${customerName !== ""})`);
    console.log("  - QuantitySelected:", QuantitySelected, `(valide: ${Boolean(QuantitySelected)})`);
    console.log("  - HoraireSelected:", HoraireSelected, `(valide: ${Boolean(HoraireSelected)})`);
    
    const isPhoneValid = Boolean(phoneNumber.trim());
    const isServiceValid = Boolean(selectedService);
    const isTableIdValid = Boolean(selectedTable);
    const isTableValid = Boolean(table);
    const isCustomersNumberValid = customersNumber !== undefined;
    const isNameValid = customerName !== "";
    const isQuantitySelected = Boolean(QuantitySelected);
    const isHoraireSelected = Boolean(HoraireSelected);
    
    const canClick = isPhoneValid && isServiceValid && isTableIdValid && 
                     isTableValid && isCustomersNumberValid && isNameValid && 
                     isQuantitySelected && isHoraireSelected;
    
    console.log("✅ Résultat canClickReservation:", canClick);
    
    return canClick;
  };
  // Fonction pour valider une réservation
  const validateReservation = async (onSuccess, onError) => {
    console.log("🔍 validateReservation - Début de la validation");
    
    // Vérifier si tous les champs requis sont remplis
    const isValid = canClickReservation();
    
    if (!isValid) {
      console.error("❌ Validation échouée - Certains champs requis sont manquants");
      
      // Afficher les champs manquants de manière plus détaillée
      const missingFields = [];
      if (!phoneNumber.trim()) missingFields.push("Numéro de téléphone");
      if (!selectedService) missingFields.push("Service");
      if (!selectedTable) missingFields.push("ID de table");
      if (!table) missingFields.push("Table");
      if (customersNumber === undefined) missingFields.push("Nombre de clients");
      if (customerName === "") missingFields.push("Nom du client");
      if (!QuantitySelected) missingFields.push("Sélection de quantité");
      if (!HoraireSelected) missingFields.push("Sélection d'horaire");
      
      console.error("📋 Champs manquants:", missingFields.join(", "));
      
      alert(
        "Les champs obligatoires sont le numéro de téléphone, la selection d'une table, la selection d'un nom et la selection d'un horaire."
      );
      onError();
      return;
    }
    
    console.log("✅ Validation réussie - Création de la réservation");
    
    try {
      // Génération d'un ID unique pour la réservation
      const idResa = uuidv4();
      console.log("🆔 ID de réservation généré:", idResa);

      // Création du timestamp
      console.log(`⏱️ Création du timestamp pour: ${selectedHoraire}, date: ${calendardate.toLocaleDateString()}`);
      const timeStart = createFirestoreTimestamp(selectedHoraire, calendardate);
      console.log("⏱️ Timestamp créé:", timeStart);
      console.log("⏱️ SERVICE SELECTED:", selectedService);

      
      // Préparation des données à envoyer
      const dataToSend = {
        id: idResa,
        idClient: "webappID",
        idService: selectedService?.id || selectedService,
        idRestau: restauSelected,
        customersCount: getCustomersNumber(),
        nameClient: customerName,
        adresse: getAddressFromSelection(),
        note: notes,
        phoneNumber: phoneNumber,
        duration: selectedService?.numberMinutesResa || 90,
        nameTable: table.name,
        tableId: selectedTable,
        timeStart: timeStart,
        state: 1,
      };
      
      console.log("📋 Données de réservation:", dataToSend);

      try {
        // Vérifier que idService n'est pas undefined avant d'envoyer
        if (dataToSend.idService === undefined) {
          console.error("❌ idService est undefined, impossible de créer la réservation");
          throw new Error("idService est undefined");
        }

        // Envoyer les données à Firestore
        console.log("📡 Envoi des données à Firestore...");
        const docRef = doc(firestore, "Reservations", idResa);
        await setDoc(docRef, dataToSend);
        
        console.log("✅ Document écrit avec succès, ID:", docRef.id);
        
        // Conversion du timestamp pour l'affichage
        const timeStartDate = timeStart
          ? new Date(timeStart.seconds * 1000)
          : new Date();
        
        console.log("🎉 Réservation réussie!");
        onSuccess(timeStartDate, customersNumber, phoneNumber, customerName);
      } catch (firestoreError) {
        console.error("❌ Erreur Firestore lors de l'ajout du document:", firestoreError);
        console.error("Code:", firestoreError.code);
        console.error("Message:", firestoreError.message);
        onError();
      }
    } catch (e) {
      console.error("❌ Erreur lors de la préparation de la réservation:", e);
      onError();
    }
  };

  // Fonction pour vérifier si un restaurant est temporairement fermé
  const checkIfRestaurantIsClosed = async (restaurantId) => {
    try {
      console.log("============= DÉBUT VÉRIFICATION RESTAURANT =============");
      console.log("🔍 Vérification du statut pour le restaurant ID:", restaurantId);
      
      // Log de l'appel à Firestore
      console.log("📡 Appel Firestore pour le document:", restaurantId);
      
      try {
        // Utilisation de la référence à la collection users
        const docRef = doc(usersCollection, restaurantId);
        const restaurantDoc = await getDoc(docRef);
        
        console.log("📥 Réponse Firestore reçue pour l'ID:", restaurantId);
        
        if (restaurantDoc.exists()) {
          console.log("✅ Document trouvé pour le restaurant ID:", restaurantId);
          const restaurantData = restaurantDoc.data();
          
          // Log détaillé des données du restaurant
          console.log("📋 Données complètes du restaurant:", JSON.stringify(restaurantData, null, 2));
          console.log("🔑 Type de temporarilyClosed:", typeof restaurantData.temporarilyClosed);
          console.log("📊 Valeur de temporarilyClosed:", restaurantData.temporarilyClosed);
          
          // S'assurer que la comparaison tient compte du type (boolean/string)
          const isClosed = 
            restaurantData.temporarilyClosed === true || 
            restaurantData.temporarilyClosed === "true";
          
          // Récupérer la raison de fermeture depuis la collection Users (document restauid, champ closedReason)
          let reason = "Ce restaurant est temporairement fermé. Veuillez choisir un autre restaurant.";
          if (isClosed) {
            console.log("🔍 Recherche du champ closedReason dans le document Users/" + restaurantId);
            if (restaurantData.closedReason) {
              console.log("🔑 Raison de fermeture trouvée dans Users/" + restaurantId + "/closedReason:", restaurantData.closedReason);
              reason = restaurantData.closedReason;
            } else {
              console.log("⚠️ Champ closedReason non trouvé dans le document Users/" + restaurantId + ", utilisation du message par défaut");
            }
          }
          
          console.log("🚫 Restaurant fermé? ", isClosed);
          console.log("============= FIN VÉRIFICATION RESTAURANT =============");
          return { isClosed, reason };
        } else {
          console.log("❌ Document du restaurant introuvable");
          console.log("============= FIN VÉRIFICATION RESTAURANT =============");
          return { isClosed: false, reason: "" };
        }
      } catch (firestoreError) {
        console.error("❌ ERREUR Firestore lors de la récupération des données:", firestoreError);
        console.log("Code d'erreur:", firestoreError.code);
        console.log("Message d'erreur:", firestoreError.message);
        console.log("============= FIN VÉRIFICATION RESTAURANT AVEC ERREUR FIRESTORE =============");
        return { isClosed: false, reason: "" };
      }
    } catch (error) {
      console.error("❌ ERREUR lors de la vérification du statut du restaurant:", error);
      console.log("============= FIN VÉRIFICATION RESTAURANT AVEC ERREUR =============");
      return { isClosed: false, reason: "" };
    }
  };

  const handleClickRestau = async (enseigne, checkOnly = false) => {
    console.log("📍 Début de handleClickRestau pour:", enseigne, checkOnly ? "(vérification seulement)" : "");
    let restauid; // Déclare restauid une seule fois ici

    switch (enseigne) {
      case "Melun":
        restauid = "I2tSGJEdBZQRxT6ZXf0r51eFbfl1";
        // restauid = "R0Z8fKPhFMQZeEj4H5Ij5sdSGlG2";
        break;
      case "Bois-Colombes":
        restauid = "L5vHL7z7dcdPVAiiXtMJGIjlLlF2";
        // brother place restauid = "MydKHgrbSmRuAv3fvlUV5xv6sCL2";
        break;
      case "Pavillon-sous-bois":
        restauid = "rQVzDhf27NfK62UE2VB6UYMkXZ43";
        break;
      case "Les Clayes-sous-bois":
        restauid = "OO7cdEYfC3gybADPrcHSkiJyTrv2";
        break;
      case "Arcueil":
        restauid = "a8UkOXK5VffDK0lvo5kzRsiWdXc2";
        break;
      case "Asnières-sur-Seine":
        restauid = "P4dVdEAfmddEnj0SMLdxoG2xCn32";
        break;
      case "Saint Ouen":
        restauid = "1QsKMWSjqcbOxFDvcaT7VqWa2393";
        break;
      case "Paris Opéra":
        restauid = "tZav8u3foIR7lRGi2M8RM1iR65b2";
        break;
      case "Argenteuil":
        restauid = "KJ2IJJOKTEMn4KJ8UCQUbn8oyBo2";
        break;
      case "Toulouse":
        restauid = "9NOAKw6WpmOuRoAloglp002VSur1";
        break;
      default:
        alert("Restau inconnu");
    }
    
    console.log("🆔 ID du restaurant pour", enseigne, ":", restauid);
    
    // Vérifier si le restaurant est temporairement fermé
    console.log("🔍 Appel de checkIfRestaurantIsClosed pour", restauid);
    const { isClosed, reason } = await checkIfRestaurantIsClosed(restauid);
    console.log(`🚫 Restaurant ${enseigne} (${restauid}) est fermé:`, isClosed);
    
    // Si c'est juste une vérification, on retourne seulement le résultat
    if (checkOnly) {
      console.log("🔍 Vérification seulement, pas de modification d'état");
      return isClosed;
    }
    
    // Mettre à jour l'état
    console.log("🔄 Mise à jour de l'état isRestaurantTemporarilyClosed:", isClosed);
    setIsRestaurantTemporarilyClosed(isClosed);
    
    // Mettre à jour la raison de fermeture
    console.log("🔄 Mise à jour de l'état closedReason:", reason);
    console.log("📝 Message de fermeture récupéré depuis Users/" + restauid + "/closedReason");
    setClosedReason(reason);
    
    // Afficher une alerte si le restaurant est fermé
    if (isClosed) {
      console.log("⚠️ Restaurant fermé, affichage de l'alerte avec le message récupéré...");
      setTimeout(() => {
        alert(reason);
        console.log("⚠️ Alerte affichée et fermée par l'utilisateur");
      }, 100); // Petit délai pour s'assurer que l'état est mis à jour
      
      console.log("🔒 Retour de handleClickRestau avec true (restaurant fermé)");
      return true; // Indique que le restaurant est fermé
    }
    
    // Si on arrive ici, le restaurant n'est pas fermé
    console.log("✅ Restaurant non fermé, sélection en cours...");
    setIsRestaurantTemporarilyClosed(false);
    setRestauSelected(restauid);
    
    // Réinitialiser les états nécessaires pour un nouveau restaurant
    console.log("🔄 Réinitialisation des états pour le nouveau restaurant");
    resetWhenRestauChange();
    
    console.log("🔓 Retour de handleClickRestau avec false (restaurant non fermé)");
    return false; // Indique que le restaurant n'est pas fermé
  };

  const getAddressFromSelection = () => {
    let address = "";
    switch (restauSelected) {
      case "I2tSGJEdBZQRxT6ZXf0r51eFbfl1":
        address = "20 Rue Sainte-Ambroise, 77000 Melun";
        break;
      case "L5vHL7z7dcdPVAiiXtMJGIjlLlF2":
        address = "203-207 Avenue d'Argenteuil, 92270 Bois-Colombes";
        break;
      case "rQVzDhf27NfK62UE2VB6UYMkXZ43":
        address = "50B Av. Aristride Briands, 93320 Les Pavillons-sous-Bois";
        break;
      case "a8UkOXK5VffDK0lvo5kzRsiWdXc2":
        address = "2 Rue Guy de Gouyon-du-Verger, 94140 Arcueil";
        break;
      case "OO7cdEYfC3gybADPrcHSkiJyTrv2":
        address = "90 Av. Henri Barbusse, 78340 Les Clayes-sous-Bois";
        break;
      case "P4dVdEAfmddEnj0SMLdxoG2xCn32":
        address = "4 Avenue Laurent Cely, Asnières-sur-Seine";
        break;
      case "1QsKMWSjqcbOxFDvcaT7VqWa2393":
        address = "10 bis Rue de l Hippodrome, Saint-Ouen-sur-Seine";
        break;
      case "tZav8u3foIR7lRGi2M8RM1iR65b2":
        address = "9 rue Danielle Casanova, 75001 Paris";
        break;
      case "KJ2IJJOKTEMn4KJ8UCQUbn8oyBo2":
        address = "75 Avenue Gabriel Péri, 95100 Argentueil";
        break;
      case "9NOAKw6WpmOuRoAloglp002VSur1":
        address = "282 rue des pyrenees, 31400 Toulouse";
        break;
      default:
        address = "Adresse inconnue";
    }
    return address;
  };

  const handleSelectTable = (tableId) => {
    console.log("🔍 handleSelectTable - Début avec tableId:", tableId);
    
    setSelectedTable(tableId);
    console.log("✅ selectedTable mis à jour:", tableId);

    // Récupération de la table réservée
    const reservedTable = tables.find((table) => tableId === table.id);
    console.log("🔎 Table trouvée:", reservedTable ? "oui" : "non");

    if (!reservedTable) {
      console.error("❌ Aucune table trouvée avec cet ID:", tableId);
      console.error("📊 Tables disponibles:", tables.map(t => t.id));
      return;
    }
    
    console.log("📋 Détails de la table:", {
      id: reservedTable.id,
      name: reservedTable.name,
      sits: reservedTable.sits
    });
    
    setTable(reservedTable);
    console.log("✅ État 'table' mis à jour");

    try {
      const dayOfWeek = getDayOfWeek(calendardate).toLowerCase();
      console.log("📅 Jour de la semaine:", dayOfWeek);

      if (!reservedTable[dayOfWeek]) {
        console.error(`❌ La table n'a pas de données pour le jour ${dayOfWeek}`);
        console.log("🔎 Jours disponibles:", Object.keys(reservedTable).filter(key => 
          ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].includes(key)
        ));
        return;
      }

      const services = reservedTable[dayOfWeek]?.services;
      console.log("🕒 Services pour ce jour:", services ? `${services.length} services trouvés` : "aucun service");
      
      if (!services || !Array.isArray(services) || services.length === 0) {
        console.error("❌ Aucun service disponible pour ce jour");
        return;
      }

      // Afficher les informations sur les services pour le débogage
      services.forEach((service, index) => {
        try {
          console.log(`DATESTARTSERVICE 🔍 Service ${index} - dateStartService:`, service.dateStartService);
          console.log(`DATEENDSERVICE 🔍 Service ${index} - dateEndService:`, service.dateEndService);
          
          const convertTimestamp = (timestamp) => {
            console.log("TIMESTAMP_CONVERSION - Début de la conversion du timestamp");
            if (!timestamp) {
              console.log("TIMESTAMP_CONVERSION - Timestamp null ou undefined");
              return null;
            }
            
            console.log("TIMESTAMP_CONVERSION - Type de timestamp:", typeof timestamp);
            
            // Cas 1: Objet Timestamp Firebase avec méthode toDate()
            if (timestamp && typeof timestamp.toDate === 'function') {
              console.log("TIMESTAMP_CONVERSION - Utilisation de toDate()");
              return timestamp.toDate();
            } 
            // Cas 2: Objet simple avec seconds/nanoseconds
            else if (timestamp && timestamp.seconds !== undefined) {
              console.log("TIMESTAMP_CONVERSION - Conversion depuis seconds/nanoseconds");
              return new Date(timestamp.seconds * 1000 + (timestamp.nanoseconds || 0) / 1000000);
            } 
            // Cas 3: Timestamp sous forme de string
            else if (typeof timestamp === 'string') {
              console.log("TIMESTAMP_CONVERSION - Conversion depuis string");
              return new Date(timestamp);
            }
            // Cas 4: Objet Date déjà
            else if (timestamp instanceof Date) {
              console.log("TIMESTAMP_CONVERSION - Déjà un objet Date");
              return timestamp;
            }
            
            console.log("TIMESTAMP_CONVERSION - Aucun format de timestamp reconnu");
            return null;
          };
          
          console.log("TIMESTAMP_PROCESSING - Début du traitement des horaires du service");
          const start = convertTimestamp(service.dateStartService);
          const end = convertTimestamp(service.dateEndService);
          
          if (start && end) {
            console.log(`TIMESTAMP_PROCESSING - Service ${index} - Horaires valides`);
            console.log(`TIMESTAMP_PROCESSING - Service ${index} - Début: ${start.toLocaleString()}, Fin: ${end.toLocaleString()}`);
            console.log(`TIMESTAMP_PROCESSING - Service ${index} - Format court: ${start.getHours()}h${start.getMinutes()} - ${end.getHours()}h${end.getMinutes()}`);
          } else {
            console.log(`TIMESTAMP_PROCESSING - Service ${index} - Erreur: dates invalides après conversion`);
            console.log(`TIMESTAMP_PROCESSING - Service ${index} - Start: ${start}, End: ${end}`);
          }
        } catch (e) {
          console.error(`❌ Erreur lors de l'affichage du service ${index}:`, e.message);
        }
      });

      console.log("🔍 Recherche du service pour l'horaire:", selectedHoraire);
      const timeInMinutes = timeStringToMinutes(selectedHoraire);
      console.log("⏱️ Horaire converti en minutes:", timeInMinutes);
      
      const service = findServiceForDateTime(timeInMinutes, services);
      console.log("🔎 Service trouvé:", service ? `ID: ${service.id}` : "aucun service correspondant");

      if (service) {
        setSelectedService(service);
        console.log("✅ selectedService mis à jour");
        
        // Mettre à jour HoraireSelected si ce n'est pas déjà fait
        if (!HoraireSelected) {
          console.log("⚠️ HoraireSelected était false, mise à jour à true");
          setHoraireSelection(true);
        }
      } else {
        console.error("❌ Aucun service ne correspond à l'horaire sélectionné:", selectedHoraire);
      }
    } catch (error) {
      console.error("❌ Erreur dans handleSelectTable:", error);
    }
    
    console.log("🏁 Fin de handleSelectTable");
  };

  useEffect(() => {
    console.log("phoneNumber setted:", phoneNumber);
  }, [phoneNumber]);

  useEffect(() => {
    console.log("table setted:", table);
  }, [table]);

  useEffect(() => {
    console.log("selectedService setted:", selectedService);
  }, [selectedService]);

  useEffect(() => {
    console.log(`🔄 useEffect[restauSelected] - Restaurant sélectionné: ${restauSelected}`);
    if (restauSelected !== null) {
      resetWhenRestauChange();

      const idrestau = restauSelected;
      console.log(`🚀 useEffect[restauSelected] - Initialisation pour restaurant ID: ${idrestau}`);
      
      // Séquence d'initialisation
      (async () => {
        try {
          console.log(`📋 useEffect[restauSelected] - Récupération des tables...`);
          const tableResult = await getAllTables(idrestau);
          console.log(`✅ useEffect[restauSelected] - ${tableResult.length} tables récupérées`);
          
          console.log(`🗓️ useEffect[restauSelected] - Récupération des réservations pour la date: ${calendardate.toLocaleDateString()}`);
          await getAllReservations(calendardate, idrestau);
          console.log(`✅ useEffect[restauSelected] - Réservations récupérées avec succès`);
          
          // On vérifie si maxCustomers a été correctement mis à jour
          console.log(`📊 useEffect[restauSelected] - Vérification de maxCustomers: ${maxCustomers}`);
          if (maxCustomers <= 1) {
            console.log(`⚠️ useEffect[restauSelected] - maxCustomers est trop bas, vérification des tables...`);
            if (tables.length > 0) {
              const recalcMaxCust = findMaxNumberMaxPersonnes(tables);
              console.log(`🔄 useEffect[restauSelected] - Recalcul de maxCustomers: ${recalcMaxCust}`);
              setMaxCustomers(recalcMaxCust);
            }
          }
        } catch (error) {
          console.error(`❌ useEffect[restauSelected] - Erreur d'initialisation:`, error);
        }
      })();
    }
  }, [restauSelected]);

  const findServiceForDateTime = (dateTime, services) => {
    console.log("Début findServiceForDateTime - timeInMinutes:", dateTime);
    
    // Vérifier si services est valide
    if (!services || !Array.isArray(services)) {
      console.log("Services invalides:", services);
      return null;
    }
    
    console.log(`Nombre de services: ${services.length}`);
    
    // Fonction utilitaire pour convertir différents formats de timestamp en Date
    const convertToDate = (timestamp) => {
      console.log("Conversion timestamp - Type:", typeof timestamp);
      
      if (!timestamp) {
        console.log("Timestamp null ou undefined");
        return null;
      }
      
      try {
        // Cas 1: Objet Timestamp Firebase avec méthode toDate()
        if (timestamp && typeof timestamp.toDate === 'function') {
          console.log("Format: Timestamp Firebase");
          return timestamp.toDate();
        }
        
        // Cas 2: Objet simple avec seconds/nanoseconds
        if (timestamp && timestamp.seconds !== undefined) {
          console.log("Format: Objet {seconds, nanoseconds}");
          return new Date(timestamp.seconds * 1000 + (timestamp.nanoseconds || 0) / 1000000);
        }
        
        // Cas 3: Timestamp sous forme de string
        if (typeof timestamp === 'string') {
          console.log("Format: String");
          return new Date(timestamp);
        }
        
        // Cas 4: Objet Date déjà
        if (timestamp instanceof Date) {
          console.log("Format: Déjà un objet Date");
          return timestamp;
        }
        
        // Cas 5: Objet avec structure inconnue, tenter JSON.stringify
        console.log("Format inconnu:", JSON.stringify(timestamp));
        return null;
      } catch (error) {
        console.error("Erreur de conversion:", error.message);
        return null;
      }
    };
    
    try {
      for (let i = 0; i < services.length; i++) {
        const service = services[i];
        
        // Log basique du service
        console.log(`Service ${i} - id:`, service.id);
        
        // Vérifier si dateStartService/dateEndService existent
        if (!service.dateStartService || !service.dateEndService) {
          console.log(`Service ${i}: propriétés manquantes`);
          continue;
        }
        
        // Log du type et structure des objets dateStart/dateEnd
        console.log(`Service ${i} - dateStartService:`, service.dateStartService);
        
        // Conversion des timestamps en objets Date
        const start = convertToDate(service.dateStartService);
        const end = convertToDate(service.dateEndService);
        
        // Vérifier si la conversion a réussi
        if (!start || !end) {
          console.log(`Service ${i}: conversion de date échouée`);
          continue;
        }
        
        // Calcul des minutes
        const startInMinutes = start.getHours() * 60 + start.getMinutes();
        const endInMinutes = end.getHours() * 60 + end.getMinutes();
        console.log(`Service ${i} - Intervalle: ${startInMinutes}-${endInMinutes}`);
        
        console.log(`Service correspondant trouvé: ${i}`);
        return service; // Retourne l'objet service complet
      }
      
      console.log("Aucun service correspondant trouvé");
      return null;
      
    } catch (error) {
      console.log("Erreur globale:", error.message);
      return null;
    }
  };

  const createFirestoreTimestamp = (selectedSlot, selectedDate) => {
    if (!selectedSlot) {
      console.log("ERROR_OCCURED__:", selectedSlot, "retuned :", Date());
      const newtimestamp = Timestamp.fromDate(new Date());
      return newtimestamp;
    }
    const timeParts = selectedSlot.split(":");
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);

    // Créez une nouvelle date qui combine la date sélectionnée et l'heure
    const combinedDate = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      hours,
      minutes
    );

    // Convertissez l'objet Date combiné en Timestamp Firestore
    const firestoreTimestamp = Timestamp.fromDate(combinedDate);

    return firestoreTimestamp;
  };

  const handleSelectDate = (date) => {
    setCalendardate(date);
  };

  useEffect(() => {
    if (tables.length > 0) {
      // Cette fonction sera appelée à chaque fois que 'calendarDate' est mise à jour
      if (calendardate) {
        console.log(
          "calendardate et allReservations au moment de l'appel:",
          calendardate,
          allReservations
        );

        getAllReservations(calendardate, restauSelected)
          .then(() => {
            console.log(
              "getAllReservations fetched successfully for",
              calendardate,
              "end",
              restauSelected
            );
          })
          .catch((error) => {
            console.error("getAllReservations to fetch tables:", error);
          });
        console.log("État des tables au moment de l'appel:", tables);
        console.log("État de calendardate au moment de l'appel:", calendardate);
        const newTimeSlots = getAvailableTimeSlots(tables, calendardate);
        setTimeSlots(newTimeSlots); // Mettre à jour les créneaux horaires disponibles
        console.log("timeslots:", timeSlots, newTimeSlots);
      }
    }
  }, [calendardate, tables]);

  useEffect(() => {
    if (tables.length > 0) {
      console.log(`🔄 useEffect[tables] - ${tables.length} tables disponibles`);
      
      // Nous avons déjà calculé maxCustomers dans getAllTables, mais vérifiez au cas où
      if (maxCustomers <= 1) {
        console.log(`⚠️ useEffect[tables] - maxCustomers est encore à ${maxCustomers}, recalcul...`);
        const maxCust = findMaxNumberMaxPersonnes(tables);
        console.log(`🔄 useEffect[tables] - Mise à jour de maxCustomers: ${maxCust}`);
        setMaxCustomers(maxCust);
      } else {
        console.log(`✅ useEffect[tables] - maxCustomers déjà défini à ${maxCustomers}`);
      }
    }
  }, [tables]); // Se déclenche chaque fois que `tables` change

  const handleSelectCustomers = (customersSelected) => {
    if (customersSelected !== undefined && customersSelected !== null) {
      console.log("customers setted", customersSelected);
      setCustomersNumber(customersSelected);
      
      // Always ensure QuantitySelected is true when customers are selected
      setQuantitySelection(true);
      console.log("Explicit setting of QuantitySelected to true");
    }
  };

  useEffect(() => {
    console.log("customersNumber:", customersNumber);
  }, [customersNumber]);

  useEffect(() => {
    console.log(
      "HoraireSelected et QuantitySelected:",
      QuantitySelected,
      HoraireSelected
    );
  }, [HoraireSelected, QuantitySelected]);

  const handleSelectSlot = (selection) => {
    console.log("🔍 handleSelectSlot - Début avec selection:", selection);

    // Ne pas générer d'erreur pour une sélection null, simplement retourner
    if (!selection) {
      console.log("⚠️ handleSelectSlot: selection est null, ignoré");
      return;
    }

    try {
      // Mise à jour de l'horaire sélectionné
      console.log(`⏱️ Mise à jour de l'horaire: "${selection.time}"`);
      setSelectedHoraire(selection.time);
      
      // Mise à jour de HoraireSelected si ce n'est pas déjà fait
      if (!HoraireSelected) {
        console.log("⚠️ HoraireSelected était false, mise à jour à true");
        setHoraireSelection(true);
      }

      // Vérifier que les tables existent dans la sélection
      if (!selection.tables || !Array.isArray(selection.tables)) {
        console.error("❌ Aucune table dans la sélection:", selection);
        return;
      }

      // Filtrer pour trouver les tableIds disponibles
      console.log(`📋 Tables disponibles pour cet horaire: ${selection.tables.length} tables`);
      console.log("📊 IDs des tables:", selection.tables);
      
      // Mise à jour des tables disponibles
      setSelectedTableIds(selection.tables);
      console.log("✅ selectedTableIds mis à jour");
      
      // Si nous n'avons qu'une seule table disponible, la sélectionner automatiquement
      if (selection.tables.length === 1) {
        console.log("🔄 Une seule table disponible, sélection automatique:", selection.tables[0]);
        handleSelectTable(selection.tables[0]);
      }
    } catch (error) {
      console.error("❌ Erreur dans handleSelectSlot:", error);
    }
    
    console.log("🏁 Fin de handleSelectSlot");
  };

  useEffect(() => {
    console.log("useEffect handleSelection horaire", selectedHoraire);
  }, [selectedHoraire]);

  useEffect(() => {
    console.log("tableIDs après mise à jour:", selectedTableIds);
  }, [selectedTableIds]); //

  useEffect(() => {
    console.log("table après mise à jour:", selectedTable);
  }, [selectedTable]); //

  useEffect(() => {
    console.log("allReservations_LAUNCHED:", allReservations);
  }, [allReservations]);

  const getAllTables = async (idRestau) => {
    try {
      console.log(`🔍 getAllTables - Récupération des tables pour restaurant: ${idRestau}`);
      
      const tablesref = query(
        collection(firestore, "TablesReservations"),
        where("idRestau", "==", idRestau)
      );
      
      console.log(`🔍 getAllTables - Exécution de la requête Firestore`);
      const fetchedTables = await getDocs(tablesref);
      console.log(`✅ getAllTables - ${fetchedTables.docs.length} tables récupérées`);

      const tablesArray = fetchedTables.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Analyse des tables pour débogage
      console.log(`📊 getAllTables - Analyse des tables:`);
      tablesArray.forEach((table, index) => {
        console.log(`  Table ${index+1}/${tablesArray.length} - ID: ${table.id} - Name: ${table.name} - Sits: ${table.sits}`);
      });
      
      // Set the tables state with the array of documents
      setTables(tablesArray);
      
      // Après avoir défini les tables, calculer le maxCustomers
      const maxCust = findMaxNumberMaxPersonnes(tablesArray);
      console.log(`🔢 getAllTables - Nombre maximum de clients calculé: ${maxCust} (actuel: ${maxCustomers})`);
      setMaxCustomers(maxCust);

      return tablesArray;
    } catch (error) {
      console.error("❌ getAllTables - Erreur:", error);
      return [];
    }
  };

  const getAllReservations = async (day, idRestau) => {
    // create from day : day 1 = lundi 00:00 et day 2 lundi 23:59:59
    try {
      const startday = new Date(day); // Copie de la date de début
      const endday = new Date(day); // Copie pour créer la date de fin
      endday.setHours(23, 59, 59);

      const reservationRef = query(
        collection(firestore, "Reservations"),
        where("idRestau", "==", idRestau),
        where("state", "not-in", [2, 4, 5]),
        where("timeStart", ">=", startday),
        where("timeStart", "<=", endday)
      );
      const fetchedReservations = await getDocs(reservationRef);
      console.log(
        "for ",
        idRestau,
        "and",
        day,
        "fetchedReservations__:",
        fetchedReservations.length
      );
      // Mapping sur les documents pour extraire les données
      const reservationData = fetchedReservations.docs.map((doc) => {
        // Récupérer les données originales
        const data = doc.data();
        // Récupérer timeStart qui est un objet Timestamp de Firestore
        const timeStart = data.timeStart;

        // recup l'id de la table
        const tableID = data.tableId;

        // Convertir duration en millisecondes (1 minute = 60000 millisecondes)
        const durationMs = data.duration * 60000;

        // Créer une nouvelle Date pour timeEnd en ajoutant la durée à timeStart
        const timeEnd = new Date(timeStart.seconds * 1000 + durationMs);
        console.log("résas fetched data:", data);

        // Convertir les objets Date en objets Timestamp de Firestore pour timeEnd
        const timeEndTimestamp = Timestamp.fromDate(timeEnd);

        console.log("timeEndTimestamp timeEndTimestamp:", timeEndTimestamp);

        // Retourner le nouvel objet avec timeStart et timeEnd en format Timestamp
        return {
          timeStart,
          timeEnd: timeEndTimestamp,
          tableID,
        };
      });
      console.log("RÉSAS FETECHED_END__:", reservationData);
      setallReservations(reservationData);
    } catch (error) {
      console.error("RÉSAS FETECHED__ERROR__:", error);
    }
  };

  const computeServicesOfDay = (
    setServicesOfDay,
    setTablesOfDay,
    tables,
    dayChosen
  ) => {
    const day = dayChosen
      .toLocaleString("en-US", { weekday: "long" })
      .toUpperCase(); // ou une autre locale selon vos besoins
    const servicesTable = [];

    tables.forEach((table) => {
      const services = table[day.toLowerCase()].services; // Assurez-vous que le nom des jours est correct et existe dans l'objet table
      if (services && services.length > 0) {
        servicesTable.push(...services);
        setTablesOfDay((tablesOfDay) => [...tablesOfDay, table]);
      }
    });

    setServicesOfDay(servicesTable);
  };
  // ...autres fonctions
  // Valeurs et fonctions à fournir à laisser en fin de déclaration car il faut déclarer au dessus
  const value = {
    resetStates,
    HoraireSelected,
    QuantitySelected,
    setQuantitySelection,
    setHoraireSelection,
    setResetStates,
    canClickReservation,
    getAddressFromSelection,
    tables,
    customerName,
    setCustomerName,
    setTables,
    maxCustomers,
    setMaxCustomers,
    getAllTables,
    getAllReservations,
    allReservations,
    setSelectedService,
    selectedService,
    calendardate,
    setCalendardate,
    timeSlots,
    setTimeSlots,
    setSelectedHoraire,
    selectedHoraire,
    handleSelectSlot,
    setSelectedTableIds,
    selectedTableIds,
    setSelectedTable,
    selectedTable,
    handleSelectTable,
    validateReservation,
    handleSelectCustomers,
    phoneNumber,
    setPhoneNumber,
    table,
    notes,
    setNotes,
    handleSelectDate,
    handleCustomerNameChanges,
    restauSelected,
    setRestauSelected,
    getCustomersNumber,
    handleClickRestau,
    isRestaurantTemporarilyClosed,
    setIsRestaurantTemporarilyClosed,
    closedReason,
    setClosedReason,
    // ...autres valeurs et fonctions
  };
  return (
    <ReservationContext.Provider value={value}>
      {children}
    </ReservationContext.Provider>
  );
};

function findMaxNumberMaxPersonnes(tables) {
  console.log(`🔎 findMaxNumberMaxPersonnes - Analyse de ${tables.length} tables`);
  
  // Démarrez avec une valeur initiale de 1 pour le nombre max de personnes
  let maxNumberMaxPersonnes = 1;
  let maxSits = 1;

  // Logging des informations pour débogage
  let foundValues = [];

  tables.forEach((table, index) => {
    // Vérifier d'abord si la table a une propriété 'sits'
    if (table.sits && typeof table.sits === 'number') {
      if (table.sits > maxSits) {
        maxSits = table.sits;
        foundValues.push(`Table ${index}: sits=${table.sits}`);
      }
    }

    // Parcourez chaque jour de la semaine
    ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].forEach((day) => {
      // Vérifiez si le jour actuel existe et a un tableau de services
      if (table[day] && table[day].services && Array.isArray(table[day].services)) {
        // Trouvez le nombre max de personnes pour chaque service de ce jour
        table[day].services.forEach((service, serviceIndex) => {
          if (service.numberMaxPersonnes && typeof service.numberMaxPersonnes === 'number') {
            if (service.numberMaxPersonnes > maxNumberMaxPersonnes) {
              maxNumberMaxPersonnes = service.numberMaxPersonnes;
              foundValues.push(`Table ${index}, ${day}, service ${serviceIndex}: numberMaxPersonnes=${service.numberMaxPersonnes}`);
            }
          }
        });
      }
    });
  });

  // Prendre le maximum entre maxSits et maxNumberMaxPersonnes
  const finalMax = Math.max(maxSits, maxNumberMaxPersonnes);
  
  console.log(`📊 findMaxNumberMaxPersonnes - Valeurs trouvées:`, foundValues);
  console.log(`📏 findMaxNumberMaxPersonnes - Max sits: ${maxSits}, Max numberMaxPersonnes: ${maxNumberMaxPersonnes}, Valeur finale: ${finalMax}`);
  
  return finalMax;
}

function convertToDate(timestampLike) {
  if (timestampLike && typeof timestampLike === 'object' && 
      timestampLike.seconds !== undefined && 
      timestampLike.nanoseconds !== undefined) {
    // Conversion en millisecondes
    return new Date(timestampLike.seconds * 1000 + timestampLike.nanoseconds / 1000000);
  }
  return null;
}

// Export du contexte par défaut pour être utilisé dans d'autres fichiers
export default ReservationContext;