// Importer l'environnement en premier
import './env';

import React, { useEffect } from 'react';
import Reservation from './reservation';
import Header from './Header';
import './App.css';
import { ReservationProvider } from './webService/Reservations';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Confirmation from './Confirmation';
import HomePage from './Home';
import RestauList from './RestauList';
import fetchTableDocument from './scripts/fetchTableDocument';
import syncTableIds from './scripts/syncTableIds';

// Cacher les logs en production de manière sécurisée
try {
  // Vérifier d'abord si window existe (environnement navigateur)
  if (typeof window !== 'undefined') {
    // Ensuite vérifier si process est défini via notre polyfill
    if (window.process && window.process.env && window.process.env.NODE_ENV === 'production') {
      console.log = () => {};
      console.warn = () => {};
      console.error = () => {};
      console.info = () => {};
      console.debug = () => {};
    }
  } else if (typeof process !== 'undefined' && process.env && process.env.NODE_ENV === 'production') {
    // Cas de Node.js
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
    console.info = () => {};
    console.debug = () => {};
  }
} catch (e) {
  // Si process n'est pas défini, ne pas planter
  console.warn("Impossible de vérifier l'environnement, les logs restent actifs");
}

function App() {
  const location = useLocation(); // Utilisé pour obtenir la route actuelle

  // Fonction qui redirige vers une autre webapp dans une nouvelle fenêtre
  const handleClickRestau = (restaurant) => {
    let url = `${restaurant}`;

    // Ouvre la nouvelle URL dans un nouvel onglet/fenêtre
    window.open(url, '_blank');
  };

  // Fonctions pour simuler des actions
  const handleClickMelun = () => handleClickRestau('https://app.mcaisse.com/clickandcollect/I2tSGJEdBZQRxT6ZXf0r51eFbfl1');
  const handleClickColombes = () => handleClickRestau('https://app.mcaisse.com/clickandcollect/L5vHL7z7dcdPVAiiXtMJGIjlLlF2');
  const handleClickClaye = () => handleClickRestau('https://app.mcaisse.com/clickandcollect/OO7cdEYfC3gybADPrcHSkiJyTrv2');
  const handleClickOpera = () => handleClickRestau('https://app.mcaisse.com/clickandcollect/tZav8u3foIR7lRGi2M8RM1iR65b2');
  const handleClickPavillon = () => handleClickRestau('https://app.mcaisse.com/clickandcollect/rQVzDhf27NfK62UE2VB6UYMkXZ43');
  const handleClickAsnières = () => handleClickRestau('https://app.mcaisse.com/clickandcollect/P4dVdEAfmddEnj0SMLdxoG2xCn32');
  const handleClickArgenteuil = () => handleClickRestau('https://app.mcaisse.com/clickandcollect/KJ2IJJOKTEMn4KJ8UCQUbn8oyBo2');

  // Liste des actions à passer au composant ActionList
  const actions = [
    { id: 1, title: 'Bois-Colombes', onClick: handleClickColombes },
    { id: 2, title: 'Melun', onClick: handleClickMelun },
    { id: 3, title: 'Les Clayes-sous-bois', onClick: handleClickClaye },
    { id: 4, title: 'Paris Opéra', onClick: handleClickOpera },
    { id: 5, title: 'Pavillon-sous-bois', onClick: handleClickPavillon },
    { id: 6, title: 'Asnières-sur-Seine', onClick: handleClickAsnières },
    { id: 7, title: 'Argenteuil', onClick: handleClickArgenteuil },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        // 1. Synchroniser les IDs des tables du restaurant spécifique
        const restaurantId = '9NOAKw6WpmOuRoAloglp002VSur1';
        const syncResult = await syncTableIds(restaurantId);
        
        if (syncResult.success) {
          console.log(`Synchronisation réussie: ${syncResult.message}`);
          console.log(`Nombre de tables traitées: ${syncResult.tables.length}`);
        } else {
          console.error(`Échec de la synchronisation: ${syncResult.message}`);
        }
        
      } catch (error) {
        console.error("Erreur lors de la récupération ou du traitement des données :", error);
      }
    };
  
    fetchData();
  }, []);

  return (
    <ReservationProvider>
      <div className="app-container">
        {/* Conditionnellement afficher le Header si on n'est pas sur la page de confirmation */}
        {location.pathname !== '/confirmation' && <Header />}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/clickandcollect" element={
            <div className="content-container">
              <RestauList actions={actions} />
            </div>
          } />
          <Route path="/reservation" element={
            <div className="content-container">
              <Reservation />
            </div>
          } />
          <Route path="/confirmation" element={<Confirmation />} />
        </Routes>
      </div>
    </ReservationProvider>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}